import React, { Component } from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { hasJwtToken, getCompanyIdFromLocalStorage } from './utils/user';

import { loadComponent } from './utils/loadable';
import { getUser } from './store/actions/UserActions';
import { getAllCompanies as getAllCompaniesService } from './services/companiesService';

let PrivateRoute;
let PublicRoute;
let SetupRoute;

const Dashboard = loadComponent('containers/Dashboard');
const Setup = loadComponent('containers/Setup');
const Login = loadComponent('containers/Login');
const ResetPassword = loadComponent('containers/ResetPassword');
const CompanyAccount = loadComponent('containers/CompanyAccount');

// eslint-disable-next-line react/display-name
PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    if (hasJwtToken()) {
      if (rest.isSuperAdmin !== undefined && rest.isUserLogged) {
        if (rest.hasUnfinishedCompany === true && rest.initialCompanySetupCompleted === false) {

          return <Redirect to='/setup/:id' />;
        } else {
          return !rest.isSuperAdmin ? <Component {...props} /> : <Redirect to='/' />;
        }
      } else {
        return <Component {...props} />;
      }
    }
    return <Redirect to='/' />;
  }} />
);

// eslint-disable-next-line react/display-name
SetupRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    return hasJwtToken() ? <Component {...props} /> : <Redirect to='/' />;
  }} />
);

// eslint-disable-next-line react/display-name
PublicRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    if (hasJwtToken()) {
      if (rest.isUserLogged) {
        if (rest.hasUnfinishedCompany === true && rest.initialCompanySetupCompleted === false) {
          if (rest.company !== undefined && rest.company !== null) {
            let companyId = rest.company.id;
            return <Redirect to={`/setup/${companyId}`} />;
          }
        }
        if (rest.company !== undefined && rest.company !== null) {
          let companyId = rest.company.id;
          if (rest.company.setup_completed === 0) {
            if (rest.company.role === 'member') {
              return <Redirect to={`/dashboard/${companyId}/my-surveys`} />;
            } else {
              return <Redirect to={`/setup/${companyId}/change-password`} />;
            }
          }
          return !rest.isSuperAdmin ? <Redirect to={`/dashboard/${companyId}`} /> : <Component {...props} />;
        } else {
          return !rest.isSuperAdmin ? <Redirect to={'/dashboard/'} /> : <Component {...props} />;
        }
      }
      return null;
    }
    return <Component {...props} />;
  }} />
);

class App extends Component {
  state = {};

  getAllCompanies = () => {
    getAllCompaniesService().then((data) => {
      if (data) {
        let companyId = getCompanyIdFromLocalStorage();
        if (companyId !== null || isNaN(companyId)) {
          let isExist = false;

          data.companies.forEach((company) => {
            if (parseFloat(company.id) === parseFloat(companyId)) {
              return isExist = true;
            }
          });
          if (isExist === false) {
            let company = data.companies[0];
            console.log('company not exist, get me first', company);
            this.setCompaniesState(company, data.companies);
          } else {
            // eslint-disable-next-line array-callback-return
            let companyStorage = data.companies.filter((company) => {
              if (parseFloat(company.id) === parseFloat(companyId)) {
                return company;
              }
            });
            console.log('company exist, finding...', companyStorage);
            this.setCompaniesState(companyStorage[0], data.companies);
          }
        } else {
          let company = data.companies[0];
          console.log('first time login', company);

          this.setCompaniesState(company, data.companies);
        }
      }
    });
  }

  setCompaniesState = (company, companies) => {
    this.setState({
      company,
      companies,
    });
  }

  componentDidMount () {
    if (hasJwtToken()) {
      this.props.getUser();
      this.getAllCompanies();
    }
  }

  render () {
    let user = this.props.user ? this.props.user : null;
    let company = this.state.company ? this.state.company : null;
    let companies = this.state.companies ? this.state.companies : null;

    let userRole;
    let isSuperAdmin;
    let isUserLogged;
    let hasUnfinishedCompany;
    let initialCompanySetupCompleted;

    if (user) {
      userRole = user.role;
      isSuperAdmin = userRole === 'superAdmin';
      isUserLogged = true;
      hasUnfinishedCompany = user.has_unfinished_company;
      initialCompanySetupCompleted = user.initial_company_setup_completed;
    }

    return (
      <Switch>
        <Route
          strict
          path='/company-account-set-up'
          render={(props) => <CompanyAccount {...props} />} />
        <PrivateRoute
          company={company}
          initialCompanySetupCompleted={initialCompanySetupCompleted}
          hasUnfinishedCompany={hasUnfinishedCompany}
          isUserLogged={isUserLogged}
          isSuperAdmin={isSuperAdmin}
          path='/dashboard/:companyId'
          component={(props) => <Dashboard {...props} user={this.props.user} companies={companies} company={company} />} />
        <SetupRoute
          company={company}
          path='/setup/:id'
          component={Setup} />
        <Route
          path='/password-reset/:reminder_token'
          component={(props) => <ResetPassword />} />
        <PublicRoute
          company={company}
          initialCompanySetupCompleted={initialCompanySetupCompleted}
          hasUnfinishedCompany={hasUnfinishedCompany}
          isUserLogged={isUserLogged}
          isSuperAdmin={isSuperAdmin}
          path='/'
          component={(props) => <Login {...props} companies={companies} company={company} />} />
      </Switch>
    );
  }
}

function mapStateToProps (state) {
  return {
    user: state.UserReducer.user,
  };
}

function matchDispatchToProps (dispatch) {
  return bindActionCreators({
    getUser,
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, matchDispatchToProps)(App));
