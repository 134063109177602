import axios, { apiRouteV1 } from './index';
import { handleResponse, handleError, handleGlobalError } from './responseHandler';

const companies = 'companies';
const companyUsers = 'company-users';
const companyAdministrators = 'company-administrators';
const single = 'single';
const users = 'users';
const events = 'events';
const markCompleted = 'mark-completed';
const markAddedMember = 'mark-users-added';
const statistics = 'statistics';
const removeProfileImage = 'remove-profile-image'
const companySignUp = 'company-sign-up'
export let getAllCompanies = () => {
  return axios.get(`${apiRouteV1}/${companies}`)
    .then((response) => {
      return handleResponse(response);
    })
    .catch((error) => {
      handleError(error);
    });
};

export let getSingleCompanyById = (id) => {
  return axios.get(`${apiRouteV1}/${companies}/${id}`)
    .then((response) => {
      return handleResponse(response);
    })
    .catch((error) => {
      handleGlobalError(error);
    });
};

export let getCompanyMeetings = (id) => {
  return axios.get(`${apiRouteV1}/${companies}/${id}/${events}`)
    .then((response) => {
      return handleResponse(response);
    })
    .catch((error) => {
      handleError(error);
    });
};

export let updateCompany = async ({
  id,
  company_name,
  abbreviated_name,
  sub_industry,
  legal_structure_id,
  employee_count_range_id,
  annual_turnover_id,
  address,
  country_id,
  time_zone,
  main_contact_first_name,
  main_contact_last_name,
  main_contact_email,
  service_plan_id,
  start_date,
  end_date,
  image_main
}) => {
  let data = {
    name: company_name,
    abbreviated_name,
    industry_type_id: parseFloat(sub_industry),
    legal_structure_id: parseFloat(legal_structure_id),
    employee_count_range_id: parseFloat(employee_count_range_id),
    annual_turnover_range_id: parseFloat(annual_turnover_id),
    address,
    country_id: parseFloat(country_id),
    timezone: time_zone,
    main_contact_first_name,
    main_contact_last_name,
    main_contact_email,
    service_plan_id: parseFloat(service_plan_id),
    start_date,
    end_date,
    image_main
  };


  return axios.put(`${apiRouteV1}/${companies}/${id}`, data)
    .then((response) => {
       return handleResponse(response);
    })
    .catch((error) => {
      handleError(error);
    });
};

export let deleteCompanyProfileImage = (id) => {
  return axios.delete(`${apiRouteV1}/${companies}/${id}/${removeProfileImage}`)
    .then((response) => {
      return handleResponse(response);
    })
    .catch((error) => {
      handleError(error);
    });
};

export let getAllCompanyUsers = (id) => {
  return axios.get(`${apiRouteV1}/${companyUsers}/${id}`)
    .then((response) => {
      return handleResponse(response);
    })
    .catch((error) => {
      handleError(error);
    });
};

export let getAllCompanyAdministrators = (id) => {
  return axios.get(`${apiRouteV1}/${companyAdministrators}/${id}`)
    .then((response) => {
      return handleResponse(response);
    })
    .catch((error) => {
      handleError(error);
    });
};

export let getSingleCompanyUser = (id) => {
  return axios.get(`${apiRouteV1}/${companyUsers}/${single}/${id}`)
    .then((response) => {
      return handleResponse(response);
    })
    .catch((error) => {
      handleError(error);
    });
};

export let getSingleCompanyAdministrator = (id) => {
  return axios.get(`${apiRouteV1}/${companyAdministrators}/${single}/${id}`)
    .then((response) => {
      return handleResponse(response);
    })
    .catch((error) => {
      handleError(error);
    });
};

export let getCompanyStatistics = (id) => {
  return axios.get(`${apiRouteV1}/${companies}/${id}/${statistics}`)
    .then((response) => {
      return handleResponse(response);
    })
    .catch((error) => {
      handleError(error);
    });
};

export let createCompanyUser = async ({
  id,
  first_name,
  last_name,
  email,
  email_confirmation,
  director_type_id,
  position_id,
  event_types,
}) => {
  let data = {
    first_name,
    last_name,
    email,
    email_confirmation,
    director_type_id,
    position_id: parseFloat(position_id),
    event_types,
  };

  return axios.post(`${apiRouteV1}/${companies}/${id}/${users}`, data)
    .then((response) => {
      return handleResponse(response);
    })
    .catch((error) => {
      handleError(error);
    });
};

export let createCompanyAdministrator = async ({
  id,
  first_name,
  last_name,
  email,
  email_confirmation,
  director_type_id,
  position_id,
  event_types,
  is_member,
}) => {
  let data = {};

  if (is_member) {
    data = {
      role: 'admin_member',
      first_name,
      last_name,
      email,
      email_confirmation,
      director_type_id,
      position_id: parseFloat(position_id),
      event_types,
    };
  } else {
    data = {
      role: 'admin',
      first_name,
      last_name,
      email,
      email_confirmation,
      director_type_id,
      position_id: parseFloat(position_id),
      event_types,
    };
  }

  return axios.post(`${apiRouteV1}/${companies}/${id}/${users}`, data)
    .then((response) => {
      return handleResponse(response);
    })
    .catch((error) => {
      handleError(error);
    });
};

export let promoteUserToAdmin = async ({
  id,
  user_id,
}) => {
  let data = {
    company_id: id,
    user_ids: [user_id],
  };

  return axios.post(`${apiRouteV1}/${companyAdministrators}`, data)
    .then((response) => {
      return handleResponse(response);
    })
    .catch((error) => {
      handleError(error);
    });
};

export let deleteCompanyAdministrator = (id, user_id) => {
  return axios.delete(`${apiRouteV1}/${companyAdministrators}`, { params: { user_id: user_id, company_id: id }})
    .then((response) => {
      return handleResponse(response);
    })
    .catch((error) => {
      handleError(error);
    });
};

export let editCompanyUser = ({
  id,
  director_type_id,
  position_id,
  event_types,
}) => {
  let data = {
    director_type_id,
    position_id: parseFloat(position_id),
    event_types
  };

  return axios.put(`${apiRouteV1}/${companyUsers}/${id}`, data)
    .then((response) => {
      return handleResponse(response);
    })
    .catch((error) => {
      handleError(error);
    });
};

export let finishCompany = async (id) => {
  return axios.put(`${apiRouteV1}/${companies}/${id}/${markCompleted}`, '')
    .then((response) => {
      return handleResponse(response);
    })
    .catch((error) => {
      handleError(error);
    });
};

export let addedMember = async (id) => {
  return axios.put(`${apiRouteV1}/${companies}/${id}/${markAddedMember}`, '')
    .then((response) => {
      return handleResponse(response);
    })
    .catch((error) => {
      handleError(error);
    });
};

export let sendCompanyInfo = async ({
    company_name,
    main_contact_first_name,
    main_contact_last_name,
    main_contact_email,
    main_contact_email_confirmation,
    event_types,
}) => {
  let data = {
    name: company_name,
    main_contact_first_name,
    main_contact_last_name,
    main_contact_email,
    main_contact_email_confirmation,
    event_types,
  };

  return axios.post(`${apiRouteV1}/${companySignUp}`, data)
    .then((response) => {
      return handleResponse(response);
    })
    .catch((error) => {
      handleError(error);
    });
};
