import 'react-app-polyfill/ie9';
import '@babel/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider, addLocaleData } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import App from './App';
import * as serviceWorker from './serviceWorker';

import store from './store/index.js';

import en from 'react-intl/locale-data/en';
import { flatten } from 'flat';

addLocaleData([...en]);

let locale = 'en-GB';
let langFiles = require('./lang/files.json');
let appLanguageResources = {};
let file;

for (let key in langFiles) {
  file = require(`./lang/${locale}/${langFiles[key].name}.json`);
  appLanguageResources[langFiles[key].name] = file;
}

appLanguageResources = flatten(appLanguageResources);

ReactDOM.render (
  <Provider store={store}>
    <IntlProvider locale={locale} messages={appLanguageResources}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </IntlProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
