import { setAuthorizationHeader } from '../services/index';

export function saveUserTokenToLocalStorage (data) {
  if (data && data.token) {
    localStorage.setItem('user-token', JSON.stringify(data));
    setAuthorizationHeader();
  }
}

export function getUserTokenFromLocalStorage () {
  return JSON.parse(localStorage.getItem('user-token'));
}

export function getJwtToken () {
  let data = getUserTokenFromLocalStorage();
  return data ? data.token : null;
}

export function hasJwtToken () {
  let data = getUserTokenFromLocalStorage();
  return data && data.token ? true : false;
}

export function saveUserToLocalStorage (user) {
  localStorage.setItem('user', JSON.stringify(user));
}

export function removeUserFromLocalStorage () {
  localStorage.removeItem('user');
}

export function removeUserTokenFromLocalStorage () {
  localStorage.removeItem('user-token');
}

export function getUserFromLocalStorage () {
  return localStorage.getItem('user');
}

export function saveCompanyIdToLocalStorage (companyId) {
  localStorage.setItem('companyId', parseFloat(companyId));
}

export function getCompanyIdFromLocalStorage () {
  return localStorage.getItem('companyId');
}

export function removeCompanyIdFromLocalStorage () {
  localStorage.removeItem('companyId');
}

export function saveChairmanDeletedReminderToLocalStorage () {
  localStorage.setItem('chairmanDeletedReminder', false);
}

export function getChairmanDeletedReminderFromLocalStorage () {
  return localStorage.getItem('chairmanDeletedReminder');
}

export function removeChairmanDeletedReminderFromLocalStorage () {
  localStorage.removeItem('chairmanDeletedReminder');
}

export function isUserSuperAdmin () {
  let user = getUserFromLocalStorage();
  if (user) {
    return user.role === 'superAdmin' ? true : false;
  } else {
    return false;
  }
}

export function isUserCompany () {
  let user = getUserFromLocalStorage();
  if (user) {
    return user.role === 'companyAdmin' ? true : false;
  } else {
    return true;
  }
}

export function isPasswordSet () {
  let user = getUserFromLocalStorage();
  if (user) {
    return user.password_is_set === 1 ? true : false;
  } else {
    return false;
  }
}

export function initialCompanySetupCompleted () {
  let user = getUserFromLocalStorage();
  if (user) {
    return user.initial_company_setup_completed;
  } else {
    return false;
  }
}

export function hasUnfinishedCompany () {
  let user = getUserFromLocalStorage();
  if (user) {
    return user.has_unfinished_company;
  } else {
    return false;
  }
}

export function checkIsMember (companies, companyId) {
  let role;
  companies.forEach((company) => {
    if (parseFloat(company.id) === parseFloat(companyId)) {
      role = company.role;
    }
  });
  return role === 'member' ? true : false;
}
