import * as actionTypes from '../actionTypes.js';

export const showMessageAlert = ({ message, type }) => ({
  type: actionTypes.SHOW_MESSAGE_ALERT,
  payload: {
    message,
    type,
  }
});

export const hideMessageAlert = () => ({
  type: actionTypes.HIDE_MESSAGE_ALERT,
});
