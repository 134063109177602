import * as actionTypes from '../actionTypes.js';

const initialState = {
  showMessageAlert: false,
  message: '',
  type: '',
};

const reducer = (state = initialState, action) => {
  switch ( action.type ) {
    case actionTypes.SHOW_MESSAGE_ALERT:
      return {
        ...state,
        showMessageAlert: true,
        message: action.payload.message,
        type: action.payload.type,
      };
    case actionTypes.HIDE_MESSAGE_ALERT:
      return {
        ...state,
        showMessageAlert: false,
      };
    default:
      return state;
  }
};

export default reducer;
