import * as actionTypes from '../actionTypes.js';
import axios, { apiRouteV1 } from '../../services/index';
import { handleResponse, handleError } from '../../services/responseHandler';

const routeUser = 'me';

export const getUser = () => {
  return function (dispatch) {
    return axios.get(`${apiRouteV1}/${routeUser}`)
      .then((response) => {
        dispatch({
          type: actionTypes.GET_USER,
          payload: response.data.user,
        });
        return handleResponse(response);
      })
      .catch((error) => {
        handleError(error);
      });
  };
}
