/**
 * User Action Types
 */
export const GET_USER = 'GET_USER';

/**
 * Companies Action Types
 */
export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANY_BY_ID = 'GET_COMPANY_BY_ID';

/**
 * Message Action Types
 */
export const SHOW_MESSAGE_ALERT = 'SHOW_MESSAGE_ALERT';
export const HIDE_MESSAGE_ALERT = 'HIDE_MESSAGE_ALERT';

export const OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL';
export const CLOSE_CONFIRMATION_MODAL = 'CLOSE_CONFIRMATION_MODAL';

export const OPEN_PREVIEW_MEMBERS_MODAL = 'OPEN_PREVIEW_MEMBERS_MODAL';
export const CLOSE_PREVIEW_MEMBERS_MODAL = 'CLOSE_PREVIEW_MEMBERS_MODAL';

export const OPEN_PREVIEW_BASE_MODULE_QUESTIONS_MODAL = 'OPEN_PREVIEW_BASE_MODULE_QUESTIONS_MODAL';
export const CLOSE_PREVIEW_BASE_MODULE_QUESTIONS_MODAL = 'CLOSE_PREVIEW_BASE_MODULE_QUESTIONS_MODAL';
