import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import store from '../../store/index';
import { showMessageAlert, hideMessageAlert } from '../../store/actions/MessagesActions';

class Messages extends React.Component {
  componentDidMount () {
    this.props.history.listen(() => {
      this.props.hideMessageAlert();
    });
  }

  render () {
    if (!this.props.showMessageAlert || !this.props.message || !this.props.type) {
      return false;
    }

    return (
      <div className={`c-alertMessage c-alertMessage--${this.props.type} c-alertMessage--${this.props.destination}`}>
        <div className='alert container'>
          <span>{ this.props.message }</span>
          <button className='c-alertMessage-close close' onClick={this.props.hideMessageAlert}>&times;</button>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = ({ MessagesReducer }) => {
  return {
    showMessageAlert: MessagesReducer.showMessageAlert,
    message: MessagesReducer.message,
    type: MessagesReducer.type,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    hideMessageAlert,
  }, dispatch);
};

export const showMessage = {
  error : (message) => {
    store.dispatch(showMessageAlert({ message, type: 'danger' }));
  },
  success : (message) => {
    store.dispatch(showMessageAlert({ message, type: 'success' }));
  },
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Messages));
