import * as actionTypes from '../actionTypes.js';

const initialState = {
  showPreviewMembersModal: false,
  showPreviewBaseModuleQuestionsModal: false,
  showConfirmationModal: false,
  component: {
    component: null,
    title: null,
  },
  confirmation: {
    text: '',
    callback: null,
  }
};

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.OPEN_PREVIEW_MEMBERS_MODAL:
      return {
        ...state,
        showPreviewMembersModal: true,
        component: {
          component: action.payload.component,
        }
      };
    case actionTypes.CLOSE_PREVIEW_MEMBERS_MODAL:
      return {
        ...state,
        showPreviewMembersModal: false,
      };
    case actionTypes.OPEN_PREVIEW_BASE_MODULE_QUESTIONS_MODAL:
      return {
        ...state,
        showPreviewBaseModuleQuestionsModal: true,
        component: {
          component: action.payload.component,
          title: action.payload.title,
        }
      };
    case actionTypes.CLOSE_PREVIEW_BASE_MODULE_QUESTIONS_MODAL:
      return {
        ...state,
        showPreviewBaseModuleQuestionsModal: false,
      };
    case actionTypes.OPEN_CONFIRMATION_MODAL:
      return {
        ...state,
        showConfirmationModal: true,
        confirmation: {
          title: action.payload.title,
          text: action.payload.text,
          link: action.payload.link,
          agree: action.payload.agree,
          disagree: action.payload.disagree,
          callback: action.payload.callback,
        }
      };
    case actionTypes.CLOSE_CONFIRMATION_MODAL:
      return {
        ...state,
        showConfirmationModal: false,
      };
    default:
      return state;
  }
};

export default reducer;
