import { showMessage } from '../containers/Messages/Messages';
import { toast } from 'react-toastify';

export const handleResponse = (response) => {
  switch (response.status) {
    case 200:
      return response.data;
    case 201:
      return response.data;
    case 204:
      return true;
    default:
      console.log('Unhandled response!', response);
  }
};

const handleMultipleErrors = (errors) => {
  if (errors) {
    for (let key in errors) {
      if (errors.hasOwnProperty(key)) {
        showMessage.error(errors[key][0]);
      }
    }
  }
};

export const handleError = (error) => {
  if (error.response && error.response.data) {
    if (error.response.data.error) {
      showMessage.error(error.response.data.error.message);
    } else if (error.response.data.message) {
      showMessage.error(error.response.data.message);
    } else if (error.response.data.messages) {
      handleMultipleErrors(error.response.data.messages);
    }
  } else {
    showMessage.error(error.message.message);
  }
};

const handleGlobalMultipleErrors = (errors) => {
  if (errors) {
    for (let key in errors) {
      if (errors.hasOwnProperty(key)) {
        toast.error(errors[key][0]);
      }
    }
  }
};

export const handleGlobalError = (error) => {
  if (error.response && error.response.data && error.response.data.message) {
    if (error.response.status === 422) {
      handleGlobalMultipleErrors(error.response.data.errors);
    } else {
      toast.error(error.response.data.message);
    }
  } else {
    toast.error(error.message);
  }
};
