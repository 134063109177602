import * as actionTypes from '../actionTypes.js';

const initialState = {
  companies: null,
  company: null,
};

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.GET_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };
    case actionTypes.GET_COMPANY_BY_ID:
      return {
        ...state,
        company: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
