import { combineReducers } from 'redux';

import UserReducer from './UserReducer';
import CompaniesReducer from './CompaniesReducer';
import MessagesReducer from './MessagesReducer';
import ModalsReducer from './ModalsReducer';

export default combineReducers({
  UserReducer,
  CompaniesReducer,
  MessagesReducer,
  ModalsReducer,
});
