import axios from 'axios';
import { getJwtToken } from '../utils/user';

export const apiRouteV1 = '/v1';
export const apiRouteV2 = '/v2';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/`,
  timeout: 100 * 1000,
});

setAuthorizationHeader();

export default instance;

export function setAuthorizationHeader () {
  instance.defaults.headers.common['X-Authorize'] = `${getJwtToken()}`;
  instance.defaults.headers.common['X-Client-Platform'] = 'SPA';
  instance.defaults.headers.common['Accept'] = 'application/json';
}
